export const aspectRatios = ["16:9", "1:1", "9:16"] as const

export const contentTypes = ["image", "video", "thumbnail"] as const
export type ContentType = (typeof contentTypes)[number]

export const visibilityTypes = ["draft", "public", "church-specific"] as const

export const approvalStatuses = ["pending", "approved", "rejected"] as const

export const notificationTypes = [
  "activity",
  "marketing",
  "post-approval",
] as const
export type NotificationType = (typeof notificationTypes)[number]
