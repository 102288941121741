import { linearTiming, TransitionSeries } from "@remotion/transitions"
import { fade } from "@remotion/transitions/fade"
import { Easing, Loop, OffthreadVideo } from "remotion"
import { z } from "zod"

import { videoOverlayElementSchema } from "@/lib/validations/element"
import { savedInputPropsSchema } from "@/lib/validations/input-props"

import { DELAY_RENDER_RETRIES } from "../constants"

const transitionDuration = 15 // frames

export const VideoOverlay = ({
  el: {
    src,
    startFromFrame,
    endAtFrame,
    naturalDurationInFrames,
    style,
    isFirstInClip,
    isLastInClip,
  },
  fromFrame,
  ...props
}: {
  el: z.infer<typeof videoOverlayElementSchema>
  fromFrame: number
} & Pick<
  z.infer<typeof savedInputPropsSchema>,
  "clippingBasedProperties" | "isBufferingDisabled"
>) => {
  const durationInFrames = endAtFrame - startFromFrame
  return (
    <TransitionSeries from={fromFrame}>
      {!isFirstInClip && (
        <TransitionSeries.Transition
          presentation={fade()}
          timing={linearTiming({
            durationInFrames: transitionDuration,
          })}
        />
      )}
      <TransitionSeries.Sequence durationInFrames={durationInFrames}>
        <Loop durationInFrames={naturalDurationInFrames}>
          <OffthreadVideo
            className="z-0 size-full bg-black object-cover object-center"
            src={src}
            pauseWhenBuffering={!props.isBufferingDisabled}
            delayRenderTimeoutInMilliseconds={20 * 1000}
            delayRenderRetries={DELAY_RENDER_RETRIES}
            style={{
              ...style,
              zIndex: 999, // videos are z=1000-i
            }}
            muted={true}
          />
        </Loop>
      </TransitionSeries.Sequence>
      {!isLastInClip && (
        <TransitionSeries.Transition
          presentation={fade({ shouldFadeOutExitingScene: true })}
          timing={linearTiming({
            durationInFrames: transitionDuration,
            // eslint-disable-next-line @typescript-eslint/unbound-method
            easing: Easing.out(Easing.ease),
          })}
        />
      )}
    </TransitionSeries>
  )
}
