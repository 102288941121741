import { ComponentProps } from "react"
import {
  fitTextToBox,
  verticalAlignmentStyleHelper,
} from "@/features/remotion/text-styles/utils"
import { baseTextPropsSchema } from "@/features/remotion/text-styles/validation"
import { z } from "zod"

import { cn } from "@/lib/utils"
import { textElementSchema } from "@/lib/validations/element"
import { textBasedStylesSchema } from "@/lib/validations/styles"

const x_padding = "px-2"

export const Text = ({
  verticalAlignment,
  ...props
}: z.infer<typeof baseTextPropsSchema> &
  ComponentProps<"span"> & {
    verticalAlignment?: z.infer<
      typeof textBasedStylesSchema
    >["verticalAlignment"]
  }) => {
  return (
    <span
      {...props}
      style={{
        ...props.style,
        ...verticalAlignmentStyleHelper(verticalAlignment),
      }}
      className={cn(
        "w-full whitespace-pre-line py-1",
        x_padding,
        props.className
      )}
    >
      {props.content}
    </span>
  )
}

export const TextFitted = ({
  el,
}: {
  el: z.infer<typeof textElementSchema>
}) => {
  if (el.enableFitText && el.text) {
    const fontSize = fitTextToBox(el)
    return (
      <Text
        content={el.text}
        className="overflow-visible"
        style={{
          ...el.style,
          fontSize,
          fontFamily: el.style.fontFamily,
          backgroundColor: el.style.backgroundColor,
          color: el.style.color,
          textAlign: el.style.textAlign,
          fontStyle: el.style.fontStyle,
          fontWeight: el.style.fontWeight,
          lineHeight: `${el.style.lineHeightPercent}%`,
          textTransform: el.style.casing,
          letterSpacing: el.style.letterSpacing,
        }}
        verticalAlignment={el.style.verticalAlignment}
      />
    )
  }
  return (
    <Text
      content={el.text}
      className="overflow-visible"
      style={{
        ...el.style,
        fontSize: el.style.fontSize,
        fontFamily: el.style.fontFamily,
        backgroundColor: el.style.backgroundColor,
        color: el.style.color,
        textAlign: el.style.textAlign,
        fontStyle: el.style.fontStyle,
        fontWeight: el.style.fontWeight,
        lineHeight: `${el.style.lineHeightPercent}%`,
        textTransform: el.style.casing,
        letterSpacing: el.style.letterSpacing,
      }}
      verticalAlignment={el.style.verticalAlignment}
    />
  )
}
