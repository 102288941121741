import { audiogramStyles } from "@/features/remotion/audiogram-styles"
import { subtitles } from "@/features/remotion/subtitle-styles"
import { z } from "zod"

import {
  containerBasedStylesSchema,
  effectBasedStylesSchema,
  fillBasedStylesSchema,
  gradientBgSupportedStylesSchema,
  layerBasedStylesSchema,
  mediaBasedStylesSchema,
  shapeBasedStylesSchema,
  subtitleBasedStyleSchema,
  textBasedStylesSchema,
} from "./styles"

export const transformPropertiesSchema = z.object({
  x: z.number(),
  y: z.number(),
  width: z.union([z.number(), z.literal("auto")]),
  height: z.union([z.number(), z.literal("auto")]),
  verticalConstraint: z
    .enum(["top", "bottom", "center", "scale"])
    .default("top"),
  horizontalConstraint: z
    .enum(["left", "right", "center", "scale"])
    .default("left"),
  rotate: z.string().optional(),
})

const baseElementSchema = z.object({
  hidden: z.boolean(),
  name: z.string().optional(),
  locked: z.boolean().optional(),
})

export const childrenBasedElementSchema = baseElementSchema.extend({
  children: z.lazy(() => z.array(nestedElementSchema)).optional(),
})
type ChildrenBasedElementSchema = z.infer<typeof baseElementSchema> & {
  children?: z.infer<typeof nestedElementSchema>[]
}

const flatDocumentElementSchema = baseElementSchema.extend({
  type: z.literal("document"),
})
// @ts-expect-error zod bug
export const nestedDocumentElementSchema: z.ZodType<
  ChildrenBasedElementSchema & z.infer<typeof flatDocumentElementSchema>
> = z.intersection(childrenBasedElementSchema, flatDocumentElementSchema)

const flatFrameElementSchema = baseElementSchema.extend({
  type: z.literal("frame"),
  transform: transformPropertiesSchema,
  style: layerBasedStylesSchema
    .and(shapeBasedStylesSchema)
    .and(fillBasedStylesSchema)
    .and(effectBasedStylesSchema)
    .and(containerBasedStylesSchema)
    .and(gradientBgSupportedStylesSchema),
})
// @ts-expect-error zod bug
export const nestedFrameElementSchema: z.ZodType<
  ChildrenBasedElementSchema & z.infer<typeof flatFrameElementSchema>
> = z.intersection(childrenBasedElementSchema, flatFrameElementSchema)

const baseMediaElementSchema = baseElementSchema.extend({
  src: z.string(),
})
const visualMediaElementSchema = baseMediaElementSchema.extend({
  transform: transformPropertiesSchema,
  naturalHeight: z.number(),
  naturalWidth: z.number(),
  style: layerBasedStylesSchema
    .and(shapeBasedStylesSchema)
    .and(mediaBasedStylesSchema)
    .and(effectBasedStylesSchema),
})
export const imageElementSchema = visualMediaElementSchema.extend({
  type: z.literal("image"),
})
export const videoElementSchema = visualMediaElementSchema.extend({
  type: z.literal("video"),
  naturalDurationInFrames: z.number(),
  loop: z.boolean().optional(),
  isMuted: z.boolean().optional(),
})
export const videoOverlayElementSchema = visualMediaElementSchema.extend({
  type: z.literal("video-overlay"),
  loop: z.boolean().optional(),
  startFromFrame: z.number(), // relative to main video
  endAtFrame: z.number(), // relative to main video
  naturalDurationInFrames: z.number(),
  isMuted: z.boolean().optional().default(true),
  isFirstInClip: z.boolean(),
  isLastInClip: z.boolean(),
  // TODO: transition styles
})
export const audioElementSchema = baseMediaElementSchema.extend({
  type: z.literal("audio"),
  naturalDurationInFrames: z.number(),
  volume: z.number(),
})
export const textElementSchema = baseElementSchema.extend({
  type: z.literal("text"),
  text: z.string(),
  transform: transformPropertiesSchema,
  enableFitText: z.boolean().optional(),
  style: layerBasedStylesSchema
    .and(textBasedStylesSchema)
    .and(fillBasedStylesSchema)
    .and(effectBasedStylesSchema),
})

export const rectangleElementSchema = baseElementSchema.extend({
  type: z.literal("rectangle"),
  style: layerBasedStylesSchema
    .and(shapeBasedStylesSchema)
    .and(fillBasedStylesSchema)
    .and(gradientBgSupportedStylesSchema)
    .and(effectBasedStylesSchema),
  transform: transformPropertiesSchema,
})
export const audiogramElementSchema = baseElementSchema.extend({
  type: z.literal("waveform"),
  transform: transformPropertiesSchema,
  style: layerBasedStylesSchema
    .and(fillBasedStylesSchema)
    .and(effectBasedStylesSchema),
  mirroringEnabled: z.boolean().optional(),
  audiogramStyleId: z
    .enum(
      Object.keys(audiogramStyles) as [
        keyof typeof audiogramStyles,
        ...(keyof typeof audiogramStyles)[],
      ]
    )
    .optional(),
})

export const subtitleStyleSchema = subtitleBasedStyleSchema
  .and(layerBasedStylesSchema)
  .and(textBasedStylesSchema)
  .and(fillBasedStylesSchema)
  .and(containerBasedStylesSchema)
  .and(shapeBasedStylesSchema)
  .and(effectBasedStylesSchema)

export const subtitleElementSchema = baseElementSchema.extend({
  type: z.literal("subtitles"),
  transform: transformPropertiesSchema,
  style: subtitleStyleSchema,
  wordsAtATime: z.number().default(8),
  animationStyle: z.enum(["scale"]).nullish(),
  disableFitText: z.boolean().optional(),
  inactiveColor: z.string().nullish(),
  subtitleStyleId: z.enum(
    Object.keys(subtitles) as [
      keyof typeof subtitles,
      ...(keyof typeof subtitles)[],
    ]
  ),
})

export const clippingElementSchema = baseElementSchema.extend({
  transform: transformPropertiesSchema,
  style: layerBasedStylesSchema
    .and(shapeBasedStylesSchema)
    .and(effectBasedStylesSchema),
  type: z.literal("clipping"),
})

const elementsWithoutChildrenSchema = z.union([
  imageElementSchema,
  videoElementSchema,
  audioElementSchema,
  textElementSchema,
  audiogramElementSchema,
  subtitleElementSchema,
  rectangleElementSchema,
  clippingElementSchema,
  videoOverlayElementSchema,
])

export const flatElementSchemaWithoutId = z.union([
  elementsWithoutChildrenSchema,
  flatFrameElementSchema,
  flatDocumentElementSchema,
])
export const flatElementSchema = z.intersection(
  flatElementSchemaWithoutId,
  z.object({ id: z.string() })
)

export const nestedElementSchemaWithoutId = z.union([
  elementsWithoutChildrenSchema,
  nestedFrameElementSchema,
  nestedDocumentElementSchema,
])
export const nestedElementSchema = z.intersection(
  nestedElementSchemaWithoutId,
  z.object({ id: z.string() })
)

export const nestedElementsWithChildren = z.union([
  nestedFrameElementSchema,
  nestedDocumentElementSchema,
])
