import { fillTextBox, fitText, measureText } from "@remotion/layout-utils"
import { z } from "zod"

import {
  subtitleElementSchema,
  textElementSchema,
} from "@/lib/validations/element"
import { textBasedStylesSchema } from "@/lib/validations/styles"

export const verticalAlignmentStyleHelper = (
  verticalAlignment: z.infer<typeof textBasedStylesSchema>["verticalAlignment"]
) =>
  verticalAlignment === "top"
    ? {
        display: "flex",
        alignItems: "flex-start",
        height: "100%",
        position: "absolute" as const,
        top: 0,
      }
    : verticalAlignment === "center"
      ? {
          position: "absolute" as const,
          top: "50%",
          transform: "translateY(-50%)",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }
      : verticalAlignment === "bottom"
        ? {
            display: "flex",
            alignItems: "flex-end",
            height: "100%",
            position: "absolute" as const,
            bottom: "1rem", // prevent text from touching the bottom of the container
          }
        : {}

export const fitTextToBox = ({
  text,
  transform,
  ...el
}: z.infer<typeof textElementSchema>): number => {
  // Binary search for the optimal font size
  let low = 8
  let high = el.style.fontSize
  const measurableStyles = {
    additionalStyles: {
      // causes false negatives for loaded fonts
      // ...verticalAlignmentStyleHelper(el.style.verticalAlignment),
      overflows: "no-wrap",
      "text-wrap": "pretty",
      lineHeight: `${el.style.lineHeightPercent}%`,
    },
    fontWeight: el.style.fontWeight === "normal" ? "400" : el.style.fontWeight,
    textTransform: el.style.casing,
    letterSpacing: el.style.letterSpacing
      ? `${el.style.letterSpacing}px`
      : "unset",
    fontFamily: el.style.fontFamily,
    validateFontIsLoaded: false, // causes false negatives for some fonts, like Lora
  }

  while (low <= high) {
    const mid = Math.floor((low + high) / 2)
    const maxBoxWidth =
      (transform.width === "auto" ? 1080 : transform.width) -
      // Text has padding of 0.5rem per side
      mid
    const maxBoxHeight =
      (transform.height === "auto" ? 720 : transform.height) - mid

    const textBox = fillTextBox({
      maxBoxWidth,
      maxLines: text.split(" ").length,
    })
    const words = text.split(" ")

    // Add all words to the text box with current font size
    let overflows = false
    let numLines = 1
    words.forEach((word) => {
      const { exceedsBox, newLine } = textBox.add({
        ...measurableStyles,
        fontSize: mid,
        text: word,
      })
      if (exceedsBox) overflows = true
      if (newLine) numLines += 1
    })

    const { height } = measureText({
      ...measurableStyles,
      fontSize: mid,
      text,
    })
    const exceedsHeight =
      (height * numLines * (el?.style?.lineHeightPercent ?? 100)) / 100 >
      maxBoxHeight
    if (!overflows && !exceedsHeight) {
      // This size fits, try a larger one
      low = mid + 8
    } else {
      // Too big, try a smaller size
      high = mid - 8
    }
  }

  // Return the largest font size that fits
  return high
}

export const fitTextElement = (
  text: string,
  el: z.infer<typeof subtitleElementSchema>
): number => {
  const dims = fitText({
    text,
    withinWidth: el.transform.width === "auto" ? 1000 : el.transform.width,
    letterSpacing: el.style.letterSpacing
      ? `${Math.floor(el.style.letterSpacing)}px`
      : undefined,
    fontFamily: el.style.fontFamily,
    fontWeight: el.style.highlightFontStrokeWidth ?? el.style.fontWeight,
    // validating the font loading in editor can cause crashes:
    // https://github.com/Post-Sunday/editor/issues/1280
    validateFontIsLoaded: true,
  })

  return Math.floor(Math.min(dims.fontSize, el.style.fontSize))
}
